<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :submit_preprocessing="submit_preprocessing"
            :slot_table_list="['operation','status','goods_logo','goods_put','class_rebate']"
            ref="list"
			:is_created_get_list="false"
        >
            <template slot="right_btn">
                <a v-if="this.$store.state.page_auth.export" @click="report()">
					<a-button class="a_btn">导出</a-button>
				</a>
            </template>
            <template slot="class_rebate" slot-scope="data">
                {{ data.record.class_rebate == 'region' ? '区域' : '总部' }}
            </template>
            <template slot="operation" slot-scope="data">
                <span v-auth="'change'">
                    <a @click="editPut(data.record)">编辑</a>
                </span>
                <a-divider v-auth="'change'" type="vertical" />
                <span>
                    <a @click="details(data.record)">查看</a>
                </span>
            </template>
        </TableList>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import { costLaunchList ,getCostType} from "@/api/cost";
import { getType } from '@/api/approval'
let columns = [
    {
        title: "申请时间",
        dataIndex: "create_time",
    },
    {
        title: "申请人",
        dataIndex: "username",
    },
    {
        title: "部门",
        dataIndex: "path_name",
    },
    {
        title: "投放客户ID",
        dataIndex: "customer_id",
    },
    {
        title: "客户名称",
        dataIndex: "customer_name",
    },
    {
        title: "投放类型",
        dataIndex: "launch_type",
    },
    {
        title: "投放周期",
        dataIndex: "launch_time",
    },
    {
        title: "投放位置",
        dataIndex: "launch_position",
    },
    {
        title: "审批状态",
        dataIndex: "approval_status_name",
    },
    {
        title: "事由",
        dataIndex: "desc",
    },
    {
        title: "返利口径",
        dataIndex: "class_rebate",
        scopedSlots: {
            customRender: "class_rebate"
        }
    },
    /* {
        title: "关联发放状态",
        dataIndex: "grant_status",
    }, */
    {
        title: "操作",
        width: 120,
        scopedSlots: {
            customRender: "operation"
        }
    },
];

export default {
    name: "Indexput",
    components: {
        EditPop,
        Form,
        TableList
    },
    data() {
        return {
            get_table_list: costLaunchList,
            submit_preprocessing: {
                array_to_string: ['department_id','type_id']
            },
            columns,
            form_data_seo: {
                list: [
                    {
                        type: "tree-select",
                        name: "department_id",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: "tree-select",
                        name: "type_id",
                        title: "投放类型",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    // {
                    //     type: "select",
                    //     name: "type_id",
                    //     title: "投放类型",
                    //     list: [],
                    //     options: {

                    //     }
                    // },
                    {
                        type: "text",
                        name: "customer",
                        title: "投放客户",
                        placeholder: '输入客户名称、ID、地址',
                        options: {

                        }
                    },
                    {
                        type: "text",
                        name: "username",
                        title: "申请人",
                        placeholder: '输入工号、姓名、手机号',
                        options: {

                        }
                    },
                    {
                        type: "range_date",
                        name: "range_date",
                        title: "创建时间",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                    {
                        type: 'select',
                        name: 'status',
                        title: '审批状态',
                        list: [
                            ...this.$config.status_all,
                            ...this.$config.approval_status_list
                        ],
                        options: {},
                    },
                    {
                        type: 'select',
                        name: 'class_rebate',
                        title: '返利口径',
                        list: [
                            {
                                key: 'region',
                                value: "区域"
                            },
                            {
                                key: 'headquarters',
                                value: "总部"
                            }
                        ],
                        options: {},
                    },
                ],
                ...this.$config.form_data_seo
            },

        };
    },
    async created() {
		// 缓存该页面
		this.$nextTick(()=>{
			// 12.21迭代：该方法目前是为了接收从contract/components/info中点击关联投放记录跳转过来的contract_id
			this.$method.setSeoFormValue(this,{
				...this.$route.query,
			})
			this.$refs.list.get_list();
		})
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department_id', 'treeData', res)
        });
        // getType({
        //     data: {
        //         type: 'investment',
        //     }
        // }).then(res => {
        //     let type_list = this.$method.to_form_list(res.data.list, 'id', 'name')
        //     this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'type_id', 'list', type_list)
        // })
        getCostType().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'type_id', 'treeData', res.data.list)
        })
    },
    methods: {
        details(data) {
			this.$keep_route.add(this.$options.name);
            this.$router.push('put_detail?id=' + data.id)
        },
		report() {
			this.$router.push({
				path:"/report/modify_report",
				query:{
					report_key:"custom_cost_launch",
					...this.$refs.list.seo_data
				}
			});
		},
        editPut(data) {
            this.$router.push({
                path:"./put_edit",
                query:{
                    id:data.id
                }
            })
        }
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>