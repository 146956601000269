import req from "../methods/req"
import method from '../methods/method'

// 获取请假审批列表
export const leaveList = function (data){
    return method.get_list({
		url:'/manage/crm.approval/leaveList',
		...data,
    })
}

export const leaveInfo = function (data){
	return req({
		url:'/manage/crm.approval/leaveInfo',
		...data
	})
}

// 获取请假审批列表
export const costList = function (data){
    return method.get_list({
		url:'/manage/crm.approval/costList',
		...data,
    })
}
export const costInfo = function (data){
	return req({
		url:'/manage/crm.approval/costInfo',
		...data
	})
}

// 获取费用投放列表
export const investmentList = function (data){
    return method.get_list({
		url:'/manage/crm.approval/investmentList',
		...data,
    })
}

//manage/crm.approval/investmentListPut 新 

export const investmentListPut = function (data){
    return method.get_list({
		url:'/manage/crm.approval/investmentListPut',
		...data,
    })
}
//获取费用投放详情
export const investmentInfo = function (data){
	return req({
		url:'/manage/crm.approval/investmentInfo',
		...data
	})
}
export const investmentPutInfo = function (data){
	return req({
		url:'/manage/crm.approval/investmentPutInfo',
		...data
	})
}
// manage/crm.approval/investmentPutInfo

// 获取获取离职列表
export const quitList = function (data){
    return method.get_list({
		url:'/manage/crm.approval/quitList',
		...data,
    })
}

//获取获取离职详情
export const quitInfo = function (data){
	return req({
		url:'/manage/crm.approval/quitInfo',
		...data
	})
}

// 获取类型
export const getType = function (data){
	return req({
		url:'/manage/crm.approval/getType',
		...data
	})
}

// 获取类型列表
export const getInvestmentType = function (data){
	return req({
		url:'/manage/crm.approval/getInvestmentType',
		...data
	})
}
// 保存
export const saveType = function (data){
	return req({
		url:'/manage/crm.approval/saveType',
		...data
	})
}
// 删除
export const removeType = function (data){
	return req({
		url:'/manage/crm.approval/removeType',
		...data
	})
}

// 转交审批
export const transferApproval = function (data){
	return req({
		url:'/manage/crm.approval/transferApproval',
		...data
	})
}

//manage/crm.approval/setTimes

export const getTimes = function (data){
	return req({
		url:'/manage/crm.approval/getTimes',
		...data
	})
}


export const setTimes = function (data){
	return req({
		url:'/manage/crm.approval/setTimes',
		...data
	})
}

//

//

export const getFlowList = (data) => method.get_list({ url: '/manage/crm.approval_flow/getFlowList', ...data })
export const formList = (data) => req({ url: '/manage/crm.approval_flow/formList', ...data })
export const addFlow = (data) => req({ url: '/manage/crm.approval_flow/addFlow', ...data })
export const getFlowInfo = (data) => req({ url: '/manage/crm.approval_flow/getFlowInfo', ...data })
export const removeFlow = (data) => req({ url: '/manage/crm.approval_flow/removeFlow', ...data })
export const changeStatus = (data) => req({ url: '/manage/crm.approval_flow/changeStatus', ...data })
